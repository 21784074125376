import { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Header from "./components/Header";
import Routes from "./components/Routes";
import axios from "axios";

import { AuthContext } from "./providers/AuthProvider";

import { useSmartScroller } from "./markdown/helpers/hooks";
import { useInternalExternalSetup } from "./helpers/hooks";
import getPages from "./markdown/helpers/getPages.js";

import snowflakeToDataSharesRedirectPaths from "./data/snowflakeToDataSharesRedirectPaths";

const CLIENT_ID = "AssociatedClientId";
const App = () => {
  const [user, setUser] = useState();
  const [isLightcastEmployee, setIsLightcastEmployee] = useState();
  const [scopes, setScopes] = useState([]);

  const [pages, setPages] = useState(null);

  const auth = useContext(AuthContext); // will always eventually be truthy
  const isAuthenticated = auth?.isAuthenticated();
  useInternalExternalSetup(auth, isAuthenticated);
  useSmartScroller();

  const history = useHistory();
  useEffect(() => {
    // once we have a value for auth, we only want this block to fire off once
    if (!user && isAuthenticated) {
      (async () => {
        const user = await auth.getUser();
        const tokenIsExpired = Date.now() / 1000 > user?.profile?.exp;

        axios.interceptors.request.use(async config => {
          try {
            return {
              ...config,
              baseURL: process.env.REACT_APP_AUTH_URL,
              headers: {
                ...config.headers,
                Authorization: `Bearer ${user.id_token}`,
              },
            };
          } catch {}

          return {
            ...config,
          };
        });

        if (tokenIsExpired) {
          history.push("/expired");
        } else {
          setUser(user);
          setIsLightcastEmployee(
            (
              user?.profile.Company ||
              user?.profile.company ||
              ""
            ).toLowerCase() === "emsi", // This will need to be changed in coordination with OAuth
          );

          // emsiauth returns a single clientId as a string, otherwise it's an array of strings
          let clientIds = user.profile[CLIENT_ID] || [];
          clientIds = typeof clientIds === "string" ? [clientIds] : clientIds;

          if (clientIds.length) {
            try {
              // populate array of populated templates
              const scopeFetchers = clientIds?.map(clientId =>
                axios.get(`client/${clientId}/availablescopes`),
              );

              Promise.all(scopeFetchers).then(async values => {
                // combine all our scopes from all clientIds
                const allScopes = await values.reduce(
                  async (scopes, currentValue) => {
                    const previousScopes = await scopes;
                    if (currentValue.status === 200) {
                      const newScopes = await currentValue.data;
                      return [...previousScopes, ...newScopes];
                    }
                    return scopes;
                  },
                  [],
                );
                setScopes(allScopes);
              });
            } catch (error) {
              console.log("Error fetching org scopes");
            }
          }
        }
      })();
    }
  }, [user, auth, isAuthenticated, history]);

  useEffect(() => {
    if (auth) {
      (async () => {
        const pages = await getPages(auth.showInternalContents());
        // const loginRequiredPages = pages.filter(page => !page.loginRequired); // created a changelog error... eventually will need to get this working when we need loginRequired
        // setPages(user ? pages : loginRequiredPages);
        setPages(
          !auth.showInternalContents()
            ? pages.filter(page => !page.internal)
            : pages,
        );
      })();
    }
  }, [auth, isAuthenticated, user]);

  // this is for the "My Org APIs" filter on the `/apis` page
  // it's only used on the Admin page and if someone checks the box
  const filteredApis = user ? scopeFilter(scopes, pages) : pages;

  // once we have proper server routing with RR7, do this at the server level and SEO will be happier
  const path = history.location.pathname;
  useEffect(() => {
    if (path.startsWith("/datasets")) {
      history.push(path.replace("/datasets", "/data-sets"));
    }
    if (path.startsWith("/bulkdata")) {
      history.push(path.replace("/bulkdata", "/data-shares"));
    }
    if (path.startsWith("/bulk-data")) {
      history.push(path.replace("/bulk-data", "/data-shares"));
    }
    if (path.startsWith("/snowflake")) {
      history.push(path.replace("/snowflake", "/data-shares"));
    }
    if (path.startsWith("/datafeed")) {
      history.push(
        path
          .replace("/datafeed", "/data-shares")
          // the old datafeed pages all had -data-feed at the end or their urls
          .replace("-data-feed", ""),
      );
    }
    if (path.includes("/sg-")) {
      history.push(path.replace("/sg-", "/singapore-"));
    }
    if (path.includes("/ca-")) {
      history.push(path.replace("/ca-", "/canada-"));
    }
    const isDataSharesSubPage = !!path.split("/data-shares")[1];
    const oldSnowflakeSubPage =
      isDataSharesSubPage &&
      snowflakeToDataSharesRedirectPaths.find(
        oldPath => oldPath.from === path.split("/data-shares")[1],
      );
    if (oldSnowflakeSubPage?.to) {
      history.push(`/data-shares${oldSnowflakeSubPage.to}`);
    }
  }, [history, path]);

  return (
    <>
      <Header
        user={user}
        isLightcastEmployee={isLightcastEmployee}
        toggleShowInternal={auth?.toggleShowInternal}
      />
      {pages && auth && (
        <Routes
          pages={pages}
          filteredApis={filteredApis}
          user={user}
          hasScopes={scopes.length}
          showInternal={auth.showInternalContents()}
          isLightcastEmployee={isLightcastEmployee}
        />
      )}
      <footer>
        <span>
          Have Questions? - Reach out to{" "}
          <a href="mailto:apisupport@lightcast.io">API Support</a>
        </span>
      </footer>
    </>
  );
};

export default App;

const scopeFilter = (scopes, pages) =>
  pages.filter(page => {
    if (!page.scope) return false;
    const pageScopes = page.scope.split("|");
    let shouldHaveAccess = false;

    pageScopes.forEach(pageScope => {
      if (scopes.includes(pageScope)) shouldHaveAccess = true;
    });

    return shouldHaveAccess;
  });
